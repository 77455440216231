import React from 'react'
import { graphql } from 'gatsby'
import CheckLink from '../components/checkLink'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Page from '../templates/page'

export const query = graphql`
  query page404($lang: String!) {
    prismicPage(uid: { eq: "404" }, lang: { eq: $lang }) {
      ...PrismicPageFragment
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  const navigation = data.prismicNavigation
  const social = data.prismicSocial
  return (
    <Layout navigation={navigation} social={social}>
      <SEO title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-3xl text-center font-black leading-tight md:text-6xl text-5xl z-10 relative flex flex-col justify-center h-screen'>
        {pageData.page_title && (
          <div>{pageData.page_title.text}</div>
        )}
        {pageData.page_text && (
          <div className='block text-2xl' dangerouslySetInnerHTML={{__html:pageData.page_text.html}} />
        )}
        {(pageData.page_button_link && pageData.page_button_text) && (
          <CheckLink className='button mx-auto mt-4' link={pageData.page_button_link}>{pageData.page_button_text}</CheckLink>
        )}
      </div>
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: Page,
  },
})
